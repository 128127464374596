<!--
Detail :
  ## 공사현장 단위로 조회 (전체 조회 X)
  ## 안전보건관리비 사용계획이 다 작성되어 제출된 공사현장에 대해서 조회
 *
examples:
 *
-->
<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <template v-if="!unavailable">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <!-- <c-plant type="none" name="plantCd" v-model="searchParam.plantCd" /> -->
            <c-select
              :comboItems="plantItems"
              itemText="plantName"
              itemValue="plantCd"
              type="none"
              name="plantCd"
              label="공사현장 (* 사용계획이 제출된 공사현장)"
              v-model="searchParam.plantCd"
            ></c-select>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-datepicker
              :range="true"
              type="month"
              defaultStart="-2y"
              defaultEnd="1M"
              label="기간"
              name="period"
              v-model="searchParam.period"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-select
              :comboItems="stepItems"
              itemText="stepperMstNm"
              itemValue="stepperMstCd"
              type="search"
              name="smfMonthlyStepCd"
              label="진행상태"
              v-model="searchParam.smfMonthlyStepCd"
            ></c-select>
          </div>
        </template>
        <template v-else>
          <q-chip class="unavailable-tag" color="red" text-color="white" icon="priority_high" label="사용계획이 제출된 공사현장이 없습니다. 제출 후 이용바랍니다." outline square/>
        </template>
      </template>
    </c-search-box>
    <c-card title="공사현장 사용계획 정보" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group v-if="unavailable" outline>
        </q-btn-group>
        <q-btn-group v-else-if="isPlant" outline>
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="add" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
        <q-btn-group v-else outline>
          <font style="font-size:0.8em;font-weight:300;" class="blinking" color="#C10015">
            ※ 공사현장관리 메뉴에서 공사현장을 추가하세요.
          </font>
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2">
          <c-text
            :disabled="true"
            :editable="editable"
            label="공사현장"
            name="plantName"
            v-model="plant.plantName"
          />
        </div>
        <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2">
          <c-text
            :disabled="true"
            :editable="editable"
            label="공사기간"
            name="projectPeriod"
            v-model="plant.projectPeriod"
          />
        </div>
        <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2">
          <c-text
            :disabled="true"
            :editable="editable"
            type="number"
            label="안전보건관리비 예산"
            name="projectSafetyCost"
            v-model="plant.projectSafetyCost">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2">
          <c-text
            :disabled="true"
            :editable="editable"
            type="number"
            label="누적사용액"
            name="stackAmount"
            v-model="plant.stackAmount">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-8 col-md-4 col-lg-4">
          <c-text
            :disabled="true"
            :editable="editable"
            label="해당 안전보건관리비 사용가능한 업체"
            name="vendorNames"
            v-model="plant.vendorNames">
          </c-text>
        </div>
      </template>
    </c-card>
    <c-table
      ref="table"
      class="q-mt-md"
      title="월별 안전관리비 실적 목록"
      tableId="monthlyPerformanceSMF"
      :columns="grid.columns"
      :data="plant.monthlyPerformance"
      @linkClick="linkClick"
    >
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import selectConfig from '@/js/selectConfig';
export default {
  name: 'monthly-performance',
  data() {
    return {
      searchParam: {
        plantCd: null,
        smfMonthlyStepCd: null,
        period: [],
        companyCd: '',
        vendorCd: '',
      },
      plant: {
        plantCd: null,
        plantName: null,
        projectPeriod: null,
        projectSafetyCost: 0,
        vendorCds: '',
        vendorNames: '',
        stackAmount: 0,
        monthlyPerformance: [],
      },
      grid: {
        columns: [
          {
            name: 'settlementMonth',
            field: 'settlementMonth',
            label: '정산월',
            align: 'center',
            style: 'width:100px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'smfMonthlyStepName',
            field: 'smfMonthlyStepName',
            label: '진행상태',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'totalMonthlyUsage',
            field: 'totalMonthlyUsage',
            label: '당월 사용 총액(원)',
            align: 'right',
            style: 'width:100px',
            sortable: true,
            type: 'cost',
          },
          {
            name: 'targetVendors',
            field: 'targetVendors',
            label: '대상 업체/시공사',
            align: 'left',
            style: 'width:250px',
            sortable: true,
          },
        ],
        data: [],
      },
      unavailable: false,
      plantItems: [],
      stepItems: [],
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      isPlant: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // 해당업체정보만 조회
      this.editable = this.$route.meta.editable;
      if (this.$store.getters.user.innerFlag === 'N') { 
        this.searchParam.vendorCd = this.$store.getters.user.deptCd;
      }
      if (!this.$store.getters.user.plantCds) {
        this.isPlant = false;
      }
      // url setting 
      this.usePlanListUrl = selectConfig.smf.usePlan.list.url
      this.listUrl = selectConfig.smf.monthlyPerformance.list.url
      // code setting
      this.$comm.getStepItems('SMF_MONTHLY_STEP_CD').then(_result => {
        _result.splice(0, 0, {
          stepperMstCd: 'SM00000000',
          stepperMstNm: '미작성',
        })
        this.stepItems = _result
      });
      // list setting
      this.getPlantItems();
    },
    getPlantItems() {
      this.$http.url = this.usePlanListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        smfUsePlanStepCd: 'SUP0000005'
      }
      this.$http.request((_result) => {
        this.plantItems = _result.data;
        if (this.plantItems && this.plantItems.length > 0) {
          if (this.$_.findIndex(this.plantItems, { plantCd: (window.sessionStorage.getItem('defaultPlantCd') || this.$store.getters.user.plantCd) }) > -1) {
            this.$set(this.searchParam, 'plantCd', (window.sessionStorage.getItem('defaultPlantCd') || this.$store.getters.user.plantCd));
          } else {
            this.$set(this.searchParam, 'plantCd', this.plantItems[0].plantCd);
          }
          this.unavailable = false;
          this.getList();
        } else {
          this.unavailable = true;
        }
      },);
    },
    getList() {
      if (this.unavailable) return;
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam
      this.$http.request((_result) => {
        this.plant = _result.data
        if (this.$store.getters.user.innerFlag === 'N') { 
          let vendors = [];
          if (this.plant.vendorCds) vendors = this.plant.vendorCds.split(",")
          if (vendors.indexOf(this.$store.getters.user.deptCd) === -1) this.editable = false;
        }
      },);
    },
    add() {
      this.linkClick({})
    },
    linkClick(row, col, index) {
      this.popupOptions.title = '월별 안전보건관리비 사용실적'; // 월별 안전보건관리비 사용실적
      this.popupOptions.param = {
        smfMonthlyPerformanceId: row.smfMonthlyPerformanceId,
        plantCd: this.plant.plantCd,
        settlementMonth: row.settlementMonth,
      };
      this.popupOptions.target = () => import(`${'./monthlyPerformanceDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
<style lang="sass">
.unavailable-tag
  margin-top: -10px !important
  margin-bottom: 10px !important
</style>